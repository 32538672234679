import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import { Button } from '@mui/material';
import blog from '../../assests/about/blogImg.png'
import envelop from '../../assests/about/envelop.png'
import blogUser from '../../assests/about/blogUser.png'
import '../../style/blog.scss'
import BlogCard from '../../Components/about/BlogCard';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from '../../Components/about/Footer';
import axios from 'axios';
import { Spinner } from 'reactstrap';
import * as moment from 'moment'
const Blog = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [blogs, setBlogs] = useState(null);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://timelink.host/api/blogs',

        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setBlogs(response.data?.data);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])
    return (
        <>
            <Navbar />
            <section className='pt-lg-5 pt-md-5 pt-4 '>
                <div className="container mt-lg-5 mt-md-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <h1 className="font-66 fw-bold blue-text">Blog</h1>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
                            <p className="font-18" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat nulla suspendisse tortor aenean dis placerat.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* ---------
            FEATURE
            ------------- */}
            <section className=' pt-4 pb-5'>
                <div className="container">
                    <div className="row d-lg-flex d-md-flex d-none">
                        <div className="col-6 pe-0">
                            <div className='p-4 h-100 d-flex align-items-center' style={{ backgroundColor: 'rgba(43, 43, 43, 0.1)', borderTopLeftRadius: '28px', borderBottomLeftRadius: '28px' }}>

                                <div className=' p-2'>
                                    <div>
                                        <Button className='btn-blue  px-3   rounded-5 font-16'>Featured</Button>
                                    </div>
                                    <p className="font-28 fw-bold text-capitalize" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                        Cryptocurrency Explained With Pros and Cons for Investment
                                    </p>
                                    <p className="font-18  " style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                        Get started with Time Link today and enhance your customer experience!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 ps-0">
                            <img src={blog} className='w-100' alt="image not found" height={330} style={{ borderTopRightRadius: '28px', borderBottomRightRadius: '28px' }} />
                        </div>
                    </div>
                    <div className="row d-lg-none d-md-none d-flex">
                        <div className="col-12">
                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------
                NEWSLISTER
                ----------- */}
            <section className='pt-5 pb-5 blog' style={{ backgroundColor: 'rgba(3, 40, 238, 1)' }}>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-12 d-lg-flex d-md-flex text-lg-start text-md-start text-center align-items-center" >
                            <img src={envelop} alt="image not found" width={60} height={60} />
                            <h1 className='fw-bold font-42 ms-3 text-white'>Subscribe to our weekly newsletter!</h1>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 d-lg-flex d-md-flex align-items-lg-center align-items-md-center">

                            <div className="input-group bg-white rounded-5">
                                <input type="email" className="form-control  p-3 font-16" placeholder="Enter email address" aria-describedby="basic-addon2" />
                                <span className="input-group-text border-0 d-lg-flex d-md-flex d-none" id="basic-addon2">
                                    <Button className='btn-blue  px-3   rounded-5 font-16'>Featured</Button>
                                </span>
                            </div>
                            <Button className='btn-blue w-100 p-3 mt-4 d-lg-none d-md-none d-block  rounded-5 font-16'>Featured</Button>

                        </div>
                    </div>
                </div>
            </section>
            {/* -----------
            latest
            ----------- */}
            <section className=' pt-5 pb-5 blog'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center">
                            <h1 className='fw-bold font-42'>Latest Posts</h1>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3 text-center">
                            <ul class="nav nav-pills mb-3 justify-content-end d-lg-flex d-md-flex d-block" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-Apps-tab" data-bs-toggle="pill" data-bs-target="#pills-Apps" type="button" role="tab" aria-controls="pills-Apps" aria-selected="false">Apps</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-Products-tab" data-bs-toggle="pill" data-bs-target="#pills-Products" type="button" role="tab" aria-controls="pills-Products" aria-selected="false">Products</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-Tutorial-tab" data-bs-toggle="pill" data-bs-target="#pills-Tutorial" type="button" role="tab" aria-controls="pills-Tutorial" aria-selected="false" >Tutorial</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                                <div className="container">
                                    <div className="row">
                                        {
                                            isLoading ?
                                                <div className="col-lg-4 col-md-6 col-12 mt-4">
                                                    <Spinner size={'md'} color='black' />
                                                </div>
                                                :
                                                blogs?.length > 0 ?
                                                    blogs.map((blog, index) => {
                                                        return (
                                                            <div className="col-lg-4 col-md-6 col-12 mt-4">
                                                                <BlogCard
                                                                    data={blog}
                                                                    path='/blogPost'
                                                                    img={blog.image}
                                                                    btn="product"
                                                                    head={blog.title}
                                                                    phara={blog.description}
                                                                    user={blog.avatar}
                                                                    userName={blog.author_name}
                                                                    date={moment(blog.created_at).format('MMMM D, YYYY')}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div className="col-lg-4 col-md-6 col-12 mt-4">
                                                        <h5>No blogs found</h5>
                                                    </div>
                                        }

                                        <div className='paginate-blog d-flex justify-content-center my-4'>
                                            <Stack spacing={2}>

                                                <Pagination
                                                    previousLabel={'Previous Page'}
                                                    nextLabel={'Next Page'}
                                                    count={3}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    hidePrevButton={currentPage === 1}
                                                />                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-Apps" role="tabpanel" aria-labelledby="pills-Apps-tab" tabindex="0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className='paginate-blog d-flex justify-content-center my-4'>
                                            <Stack spacing={2}>

                                                <Pagination
                                                    previousLabel={'Previous Page'}
                                                    nextLabel={'Next Page'}
                                                    count={3}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    hidePrevButton={currentPage === 1}
                                                />                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-Products" role="tabpanel" aria-labelledby="pills-Products-tab" tabindex="0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className='paginate-blog d-flex justify-content-center my-4'>
                                            <Stack spacing={2}>

                                                <Pagination
                                                    previousLabel={'Previous Page'}
                                                    nextLabel={'Next Page'}
                                                    count={3}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    hidePrevButton={currentPage === 1}
                                                />                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-Tutorial" role="tabpanel" aria-labelledby="pills-Tutorial-tab" tabindex="0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                                            <BlogCard path='/blogPost' img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                                        </div>
                                        <div className='paginate-blog d-flex justify-content-center my-4'>
                                            <Stack spacing={2}>

                                                <Pagination
                                                    previousLabel={'Previous Page'}
                                                    nextLabel={'Next Page'}
                                                    count={3}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    hidePrevButton={currentPage === 1}
                                                />                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Blog;
