import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import '../../style/blog.scss'
import blogUser from '../../assests/about/blogUser.png'
import blog from '../../assests/about/blogImg.png'
import envelop from '../../assests/about/envelop.png'
import { Button } from '@mui/material';
import BlogCard from '../../Components/about/BlogCard';
import Footer from '../../Components/about/Footer';
import ScrollToTop from '../../Components/ScrollToTop';
import { useLocation, useNavigate } from 'react-router-dom';
import * as moment from 'moment'
import axios from 'axios';
import { Spinner } from 'reactstrap';


const BlogPost = () => {
    const location = useLocation();
    const  data  = location.state;
    const navigate = useNavigate();
     const [isLoading, setIsLoading] = useState(true);
    const [blogs, setBlogs] = useState(null);
 useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://timelink.host/api/blogs',

        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setBlogs(response.data?.data);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])
    return (
        <>
        <ScrollToTop/>
            <Navbar />
            <section className='pt-5 pb-5 '>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-10 col-12">

                            <div className='align-items-center d-flex'>
                                <img src={data?.avatar} className='rounded-5 ' width={50} height={50} alt="avatar" />
                                <div className='ms-3'>
                                    <p className="font-16 fw-bold mb-0 text-uppercase">{data.author_name}</p>
                                    <p className="font-16 mb-0">{moment(blog.created_at).format('MMMM D, YYYY')}</p>

                                </div>
                            </div>
                            <h1 className="font-66 fw-bold  mt-4">{data?.title}</h1>
                            <p className="font-18" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                            </p>
                        </div>
                        <div className="col-12 mt-lg-5 mt-md-5 mt-3 pb-lg-5 pb-md-5 pb-3">
                            <img src={data?.image} className='w-100' alt="Blog" />

                        </div>
                        <div className="col-lg-8 col-md-8 col-12">
                                                           <RichTextComponent content={data?.description} />

                        </div>
                      

                    </div>
                </div>
            </section>
            {/* ----------
                NEWSLISTER
                ----------- */}
            <section className='pt-5 pb-5 blog ' style={{ backgroundColor: 'rgba(3, 40, 238, 1)' }}>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-12 d-lg-flex d-md-flex text-lg-start text-md-start text-center align-items-center" >
                            <img src={envelop} alt="image not found" width={60} height={60} />
                            <h1 className='fw-bold font-42 ms-3 text-white'>Subscribe to our weekly newsletter!</h1>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 d-lg-flex d-md-flex align-items-lg-center align-items-md-center">

                            <div className="input-group bg-white rounded-5">
                                <input type="email" className="form-control  p-3 font-16" placeholder="Enter email address" aria-describedby="basic-addon2" />
                                <span className="input-group-text border-0 d-lg-flex d-md-flex d-none" id="basic-addon2">
                                    <Button className='btn-blue  px-3   rounded-5 font-16'>Featured</Button>
                                </span>
                            </div>
                            <Button className='btn-blue w-100 p-3 mt-4 d-lg-none d-md-none d-block  rounded-5 font-16'>Featured</Button>

                        </div>
                    </div>
                </div>
            </section>
            {/* -----------
            latest
            ----------- */}
            <section className=' pt-5 pb-5 blog'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center">
                            <h1 className='fw-bold font-42'>Latest Posts</h1>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-lg-end text-md-end text-center">
                            <Button
                                onClick={() => {
                                    navigate('/blog')
                            }}
                                className='btn-gray rounded-5 w-full p-3  font-16'>View all</Button>
                        </div>
                    </div>





                    <div className="row my-4">
                        {
                                            isLoading ?
                                                <div className="col-lg-4 col-md-6 col-12 mt-4">
                                                    <Spinner size={'md'} color='black' />
                                                </div>
                                                :
                                                blogs?.length > 0 ?
                                                    blogs.map((blog, index) => {
                                                        return (
                                                            <div className="col-lg-4 col-md-6 col-12 mt-4">
                                                                <BlogCard
                                                                    data={blog}
                                                                    path='/blogPost'
                                                                    img={blog.image}
                                                                    btn="product"
                                                                    head={blog.title}
                                                                    phara={blog.description}
                                                                    user={blog.avatar}
                                                                    userName={blog.author_name}
                                                                    date={moment(blog.created_at).format('MMMM D, YYYY')}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div className="col-lg-4 col-md-6 col-12 mt-4">
                                                        <h5>No blogs found</h5>
                                                    </div>
                                        }



                    </div>


                </div>
            </section>
            <Footer />
        </>
    );
}

export default BlogPost;


function RichTextComponent({ content }) {
  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );
}
